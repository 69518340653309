import { ref, type UnwrapRef } from 'vue';
import { useNuxtApp } from '#app';
import type { LocalStorage } from '~/interfaces';

export function useTmpLocalStorage<T>(key: string) {
  const nuxtApp = useNuxtApp();
  const $locally = nuxtApp.$locally as LocalStorage;

  const storedValue = $locally.getItem(key);
  const value = ref<T | null>(storedValue ? (JSON.parse(storedValue) as T) : null);

  const saveValue = (objectToSave: T) => {
    value.value = objectToSave as UnwrapRef<T>;
    $locally.setItem(key, JSON.stringify(objectToSave));
  };

  const clearValue = () => {
    value.value = null;
    $locally.removeItem(key);
  };

  const loadValue = () => {
    const item = $locally.getItem(key);
    value.value = item ? (JSON.parse(item) as UnwrapRef<T>) : null;
  };

  return {
    value,
    saveValue,
    clearValue,
    loadValue
  };
}
